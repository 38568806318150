import React, { HTMLAttributes } from 'react';
import H2 from '../Blocks/H2/h2';
import PlaceImage from '../Blocks/PlaceImage/placeImage';

import './photoLeftTextRight.css';

interface PhotoLeftTextRightProps extends HTMLAttributes<HTMLElement>{
  alt: string;
  heading: string;
  fileURL: string;
  dataSal?: string;
  salDelay?: string;
  salEasing?: string;
}

const PhotoLeftTextRight = ({ children, alt, fileURL, heading, salDelay, dataSal, salEasing }: PhotoLeftTextRightProps) => {
  return (
        <section data-sal-easing={dataSal} data-sal={dataSal} data-sal-delay={salDelay} className="PhotoLeftTextRight">
            <PlaceImage imageClass="PhotoLeftTextRight-image" filename={fileURL} alt={alt}/>
            <div className="PhotoLeftTextRight-text">
              <H2>{heading}</H2>
              {children}
            </div>
        </section>
  );
};

export default PhotoLeftTextRight;
