import React from 'react';
import H2 from '../Blocks/H2/h2';
import H3 from '../Blocks/H3/h3';

import './quoteSection.css';

interface QuoteSectionProps {
  quote: string;
  name: string;
  dataSal?: string;
  salDelay?: string;
  salEasing?: string;
}

const QuoteSection = ({ quote, name, dataSal, salDelay, salEasing }: QuoteSectionProps) => {
  return (
        <section data-sal-easing={salEasing} data-sal={dataSal} data-sal-delay={salDelay} className="QuoteSection">
            <H2 className="nomargin">{`"${quote}"`}</H2>
            <H3 className="QuoteSection-subhead">{name}</H3>
        </section>
  );
};

export default QuoteSection;
