import React from 'react';

import './h3.css';

interface H3Props {
  children: string;
  className?: string;
}

const H3 = ({ children, className }: H3Props) => {
  return (
        <h3 className={className ? `H3 ${className}` : 'H3'}>
            {children}
        </h3>
  );
};

export default H3;
