import React from 'react';

import { Performance } from '../performanceSection';

import './performanceItem.css';

interface PerformanceItemProps extends Performance{

}

const PerformanceItem = ({ metric, label }: PerformanceItemProps) => {
  return (
        <div className="PerformanceItem">
            <span className="PerformanceItem-metric">{metric}</span>
            <span className="PerformanceItem-label">{label}</span>
        </div>
  );
};

export default PerformanceItem;
