import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import HeroSection from '../components/Blocks/HeroSection/heroSection';
import Paragraph from '../components/Blocks/Paragraph/paragraph';
import PlaceImage from '../components/Blocks/PlaceImage/placeImage';
import Section from '../components/Blocks/Section/section';
import FullscreenPhotoSection from '../components/FullscreenPhotoSection/fullscreenPhotoSection';
import Layout from '../components/layout';
import PerformanceSection from '../components/PerformanceSection/performanceSection';
import PhotoLeftTextRight from '../components/PhotoLeftTextRight/photoLeftTextRight';
import QuoteSection from '../components/QuoteSection/quoteSection';
import SEO from '../components/SEO/seo';

const KomilfoPage = () => {
  const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `,
  );

  return (
    <Layout >
        <SEO lang="ru" title={'Research for Komilfo'} keywords={['design', 'UX', 'UI', 'Artyom Alekseev', 'Артём Алексеев', 'website', 'usability']} description="How to Conduct UX Research Using CJM, user scenarios, and Personas. How to argue and prove the value of UX design.">
        </SEO>

        <HeroSection animationType="globe" heading="How to conduct quality UX research" desc="User research for creating a app."/>

        <Section heading="Project">
            <Paragraph>Komilfo is a chain of brand clothing resale boutiques. They have two types of users - a buyer, a consignor (a person who hands over luxury items to the store) and buyer that also consign clothes.</Paragraph>
            <Paragraph>Komilfo team decided to develop an application and implement a new loyalty program. Creating an app that takes user experience into account - increases loyalty so that people often re-buy and recommend the store to their friends.</Paragraph>
            <Paragraph>The main goal of this research is to make the app more convenient for customers.</Paragraph>
        </Section>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Survey">
            <Paragraph>After talking with Andrey, the founder of the chain, we decided to conduct a customer survey. For this, we have compiled a questionnaire for each type of user - buyer and consignor.</Paragraph>
            <Paragraph>We collected both quantitative and qualitative data. From "Which of these apps do you use every day?" To "When you drop off your items, what takes the most time?"</Paragraph>
            <Paragraph>This data helped us create a portrait of people who go to the boutique.</Paragraph>
            <PlaceImage filename="komilfo_viz.png" alt="Questionnaire results"/>
        </Section>

        <PhotoLeftTextRight salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Personas" fileURL="personas.png" alt="Personas">
            <Paragraph>After interviewing people, we came up with a "persona", a method of representing the user, to take into account the characteristics of our users when designing interfaces.</Paragraph>
            <Paragraph>For example, knowing that a person uses Instagram and other modern applications gives us the opportunity to use a controversial but handy element like a hamburger menu.</Paragraph>
        </PhotoLeftTextRight>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Customer Journey Map">
            <Paragraph>Imbued with empathy for the user, we began to build CJM - a method that helps to assess the points of contact between the business and the client and find out the pain they may have.</Paragraph>
            <Paragraph>For example, here we realized that it would be a separate marketing task to "wean" shoppers from using Instagram to buy and book goods.</Paragraph>
            <PlaceImage filename="komilfo_cjm.png" alt="Customer Journey Map"/>
        </Section>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="User Scenarios">
            <Paragraph>After a more general technique, we moved on to a specific one - user scenarios. Here we define the main scenarios for which the application will be used. For example, "Find specific clothes", "Check the availability of clothes".</Paragraph>
            <Paragraph>Yellow stickers are questions, green ideas, and purple comments.</Paragraph>
        </Section>

        <FullscreenPhotoSection salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" fileURL="us.png" alt="User Scenarios"/>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Result">
            <Paragraph>At the end, we made a presentation and presented the results of the study.</Paragraph>
            <Paragraph>In the initial plan, there were many additional features, and we had a fear that they could complicate and make the interface less convenient in the future.</Paragraph>
            <Paragraph>But thanks to the data we collected, we were able to more clearly argue our decisions.</Paragraph>
        </Section>

        <PerformanceSection salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" performances={[{ id: 1, metric: '120', label: 'Respondents' }, { id: 1, metric: '2 mos', label: 'Of Work' }, { id: 1, metric: '100', label: 'cups of coffee' }]}/>

        <QuoteSection salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" quote="Thank you very much for the work done!" name="Michael, Marketing Director of Komilfo"/>
    </Layout>
  ); };

export default KomilfoPage;
