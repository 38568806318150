import React from 'react';
import PerformanceItem from './PerformanceItem/performanceItem';

import './performanceSection.css';

export type Performance = {
  id: number;
  metric: string,
  label: string,
};

interface PerformanceSectionProps {
  performances: Performance[];
  dataSal?: string;
  salDelay?: string;
  salEasing?: string;
}

const PerformanceSection = ({ performances, dataSal, salDelay, salEasing }: PerformanceSectionProps) => {
  return (
        <section data-sal-easing={salEasing} data-sal={dataSal} data-sal-delay={salDelay} className="PerformanceSection">
            {performances.map(val => <PerformanceItem id={val.id} metric={val.metric} label={val.label}/>)}
        </section>
  );
};

export default PerformanceSection;
